import { ThemeProvider } from '@podiumhq/design-system';
import { RumPublicApi } from '@datadog/browser-rum-core';
import ReactDOM from 'react-dom';
import Router from './Router';
import initDatadog from 'shared/initDatadog';
import 'sanitize.css/sanitize.css';
import './index.css';

declare global {
  interface Window {
    DD_RUM?: RumPublicApi & {
      startView?(name?: string): void;
    };
  }
}

initDatadog();

ReactDOM.render(
  <ThemeProvider>
    <Router />
  </ThemeProvider>,
  document.getElementById('root')
);
