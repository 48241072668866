/**
 * Everything amplitude tracking!
 *
 * Read the Docs: https://podium.atlassian.net/wiki/spaces/EN/pages/2378956811/Niffler+Amplitude+Tracking
 */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { tracking, useTracking } from '@podiumhq/tracking';
import merge from 'lodash/merge';

import env from 'env';

import isQABot from 'shared/isQABot';
import isMocked from 'shared/isMocked';
import useCurrentUserQuery from 'shared/apollo/useCurrentUserQuery';

import INVOICE_TRACKING_EVENTS from 'apps/Invoice/trackingEvents';
import ONBOARDING_TRACKING_EVENTS from 'apps/Onboarding/trackingEvents';
import PAYMENT_ACTIVITY_TRACKING_EVENTS from 'embedded/PaymentActivity/trackingEvents';
import PAYMENT_STATUS_TRACKING_EVENTS from 'embedded/PaymentActivity/PaymentDetails/trackingEvents.js';
import UNIVERSAL_PAYMENTS_TRACKING_EVENTS from 'embedded/UniversalPayments/trackingEvents.js';
import RECEIPT_TRACKING_EVENTS from 'apps/Receipt/trackingEvents';
import ESTIMATES_TRACKING_EVENTS from 'apps/Estimate/trackingEvents';

/**
 * The aggregated events schema for Amplitude events.
 */
const EVENTS = {
  payments: merge(
    ONBOARDING_TRACKING_EVENTS,
    PAYMENT_ACTIVITY_TRACKING_EVENTS,
    PAYMENT_STATUS_TRACKING_EVENTS,
    UNIVERSAL_PAYMENTS_TRACKING_EVENTS
  ),
  paymentsConsumer: merge(INVOICE_TRACKING_EVENTS, RECEIPT_TRACKING_EVENTS),
  estimatesConsumer: ESTIMATES_TRACKING_EVENTS,
};

/**
 * Used to wrap your sub-app. Enables you to track Amplitude events.
 *
 * @param {Object} props Props for the AmplitudeProvider component.
 * @param {boolen} props.skipCurrentUser If true, skips fetching and identifying the current user.
 *                                       Defaults to false. Set to true when no user can be identified on the page. (Public apps)
 */
function AmplitudeProvider({ children, skipCurrentUser = false }) {
  const { Track } = useTracking();

  const { currentUser, loading } = useCurrentUserQuery({
    skip: skipCurrentUser,
  });

  useEffect(() => {
    if (skipCurrentUser) {
      setup({});
    } else if (!loading && currentUser) {
      setup({ currentUser });
    }
  }, [currentUser, skipCurrentUser, loading]);

  return <Track>{children}</Track>;
}

AmplitudeProvider.propTypes = {
  skipCurrentUser: PropTypes.bool,
  children: PropTypes.object.isRequired,
};

function setup({ currentUser }) {
  const enabled =
    process.env.NODE_ENV === 'production' && !isQABot() && !isMocked();

  const attributionConfig = {
    includeReferrer: true,
    includeUtm: true,
    includeGclid: true,
  };

  tracking.init({
    enabled,
    events: EVENTS,
    debug: false,
    userGroupProperties: ['locationUid'],
    amplitudeToken: env.REACT_APP_AMPLITUDE_TOKEN,
    amplitudeConfig: {
      ...attributionConfig,
      batchEvents: true,
      saveEvents: true,
      eventUploadPeriodMillis: 10000,
      plan: {
        version: env.REACT_APP_VERSION,
      },
    },
  });

  if (currentUser) {
    tracking.setUserUid(currentUser.uid);
    tracking.setUserProperties({
      'Organization UID': currentUser.organizationUid,
    });

    tracking.setGroup('organizationUid', currentUser.organizationUid);
  }
}

export default AmplitudeProvider;
